import React, {
  useEffect,
  useLayoutEffect,
  useState,
  Suspense,
  lazy,
} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// loggedIn
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "./redux/Reducers/user";

// toast alert
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Verify from "./Component/authorisation/Verify";
import Admin from "./Component/admin/Admin";
import GuidePage from "./Component/loggedIn/Guides/GuidePage/GuidePage";

const Pricing = lazy(() => import("./Component/notLoggedIn/Pricing/Pricing"));
const Offer = lazy(() => import("./Component/offers/Offer"));
const Guides = lazy(() => import("./Component/loggedIn/Guides/Guides"));
const AdminForm = lazy(() => import("./Component/offers/AdminForm/AdminForm"));
const OfferPage = lazy(() => import("./Component/offers/OfferPage/OfferPage"));
const AuthRoutes = lazy(() => import("./Component/AuthRoutes/AuthRoutes"));
const Setting = lazy(() => import("./Component/loggedIn/UserProfile/Setting"));
const ProfitTracker = lazy(() =>
  import("./Component/loggedIn/ProfitTracker/ProfitTracker")
);
const Calculator = lazy(() =>
  import("./Component/loggedIn/Tools/Calcs/DutchingCalc")
);
const GridLoader = lazy(() => import("react-spinners/GridLoader"));
const Upgrade = lazy(() => import("./Component/loggedIn/Upgrade/Upgrade"));

// import Pricing from "./Component/notLoggedIn/Pricing/Pricing";
// import Offer from "./Component/offers/Offer";
// import AdminForm from "./Component/offers/AdminForm/AdminForm";
// import OfferPage from "./Component/offers/OfferPage/OfferPage";
// import AuthRoutes from "./Component/AuthRoutes/AuthRoutes";
// import Setting from "./Component/loggedIn/UserProfile/Setting";
// import ProfitTracker from "./Component/loggedIn/ProfitTracker/ProfitTracker";
// import Calculator from "./Component/loggedIn/Tools/Calcs/DutchingCalc";
// import GridLoader from "react-spinners/GridLoader";
// import Upgrade from "./Component/loggedIn/Upgrade/Upgrade";

// Layout
const Navbar = lazy(() => import("./Component/layout/Navbar"));
const Footer = lazy(() => import("./Component/layout/Footer"));
// import Navbar from "./Component/layout/Navbar";
// import Footer from "./Component/layout/Footer";

// notLoggedIn
const Home = lazy(() => import("./Component/notLoggedIn/Homepage/Home"));
const Features = lazy(() =>
  import("./Component/notLoggedIn/Features/Features")
);
const ContactUs = lazy(() =>
  import("./Component/notLoggedIn/ContactUs/ContactUs")
);

// import Home from "./Component/notLoggedIn/Homepage/Home";
// import Features from "./Component/notLoggedIn/Features/Features";
// import ContactUs from "./Component/notLoggedIn/ContactUs/ContactUs";

const Login = lazy(() => import("./Component/authorisation/Login"));
const JoinNow = lazy(() => import("./Component/authorisation/JoinNow"));
// import Login from "./Component/authorisation/Login";
// import JoinNow from "./Component/authorisation/JoinNow";

const Dashboard = lazy(() =>
  import("./Component/loggedIn/Dashboard/Dashboard")
);
const Payment = lazy(() => import("./Component/Payment/Payment"));
const Success = lazy(() => import("./Component/Payment/Success"));
const Failed = lazy(() => import("./Component/Payment/Failed"));
const ForgetPassword = lazy(() =>
  import("./Component/authorisation/ForgetPassword")
);
const DutchMatcher = lazy(() => import("./Dutchmatcher/pages/Home"));

// import Dashboard from "./Component/loggedIn/Dashboard/Dashboard";
// import Payment from "./Component/Payment/Payment";
// import Success from "./Component/Payment/Success";
// import Failed from "./Component/Payment/Failed";
// import ForgetPassword from "./Component/authorisation/ForgetPassword";
// import DutchMatcher from "./Dutchmatcher/pages/Home";

function App() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const userstate = useSelector((state) => state.user);
  const loadingstate = useSelector((state) => state.loading);

  useEffect(() => {
    setloading(true);
    axios
      .post("/api/user/isauth", {
        withCredentials: true,
      })
      .then(({ data }) => {
        dispatch(userActions.CHANGE_LOGIN(true));
        dispatch(userActions.UPDATE_USER(data.user));
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }, [dispatch]);

  if (loading) {
    return (
      <div className="bg-[#ffffffba] fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center">
        <GridLoader
          color="#f21285"
          loading={loading}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else {
    return (
      <Router>
        <Suspense
          fallback={
            <div className="bg-[#ffffffba] fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center">
              <GridLoader
                color="#f21285"
                loading={loadingstate.loading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          }
        >
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/join" element={<JoinNow />} />
            <Route path="/verify" element={<Verify />} />

            <Route
              path="/dashboard"
              element={
                <AuthRoutes>
                  <Dashboard />
                </AuthRoutes>
              }
            />

            <Route
              path="/admin"
              element={
                <AuthRoutes>
                  <Admin />
                </AuthRoutes>
              }
            />

            <Route path="/forget" element={<ForgetPassword />} />

            <Route path="/payment" element={<Payment />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Failed />} />
            <Route path="/upgrade" element={<Upgrade />} />

            {/* Dutchmatcher */}
            <Route
              path="/tools"
              element={
                <AuthRoutes>
                  <DutchMatcher />
                </AuthRoutes>
              }
            />

            <Route
              path="/profittracker"
              element={
                <AuthRoutes>
                  <ProfitTracker />
                </AuthRoutes>
              }
            />

            <Route
              path="/setting"
              element={
                <AuthRoutes>
                  <Setting />
                </AuthRoutes>
              }
            />
            <Route
              path="/subscription"
              element={
                <AuthRoutes>
                  <Setting />
                </AuthRoutes>
              }
            />

            <Route
              path="/calculator"
              element={
                <AuthRoutes>
                  <Calculator />
                </AuthRoutes>
              }
            />

            <Route
              path="/guides"
              element={
                <AuthRoutes>
                  <Guides />
                </AuthRoutes>
              }
            />

            <Route
              path="/offers/sports/signup"
              element={
                <AuthRoutes>
                  <Offer key={1} event="sports" type="signup" />
                </AuthRoutes>
              }
            />
            <Route
              path="/offers/sports/reload"
              element={
                <AuthRoutes>
                  <Offer key={2} event="sports" type="reload" />
                </AuthRoutes>
              }
            />
            <Route
              path="/offers/casino/signup"
              element={
                <AuthRoutes>
                  <Offer key={3} event="casino" type="signup" />
                </AuthRoutes>
              }
            />
            <Route
              path="/offers/casino/reload"
              element={
                <AuthRoutes>
                  <Offer key={4} event="casino" type="reload" />{" "}
                </AuthRoutes>
              }
            />

            <Route
              path="/offerform"
              element={
                <AuthRoutes roles={["admin"]}>
                  {" "}
                  <AdminForm />{" "}
                </AuthRoutes>
              }
            />

            <Route
              path="/offer/:id"
              element={
                <AuthRoutes>
                  <OfferPage />
                </AuthRoutes>
              }
            />

            <Route
              path="/guide/:id"
              element={
                <AuthRoutes>
                  <GuidePage />
                </AuthRoutes>
              }
            />
          </Routes>

          {loadingstate.loading && (
            <div className="bg-[#ffffffba] fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center">
              <GridLoader
                color="#f21285"
                loading={loadingstate.loading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          <Footer />
          <ToastContainer />
        </Suspense>
      </Router>
    );
  }
}

export default App;
